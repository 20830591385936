html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #0A192F;
  color: #e6f1ff;
}

main {
  margin: 16px;
}

.nav {
  display: flex;
  position: fixed;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
  background: #0A192F;
  color: #b8c3e1;
  font-family: Helvetica;
  font-weight: 300;
  font-size: 1.2rem;
  top: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  transition: top 0.3s;
}

.nav > .nav-header {
  display: inline;
}

.nav > .nav-header > .nav-title {
  display: inline-block;
  font-size: 35px;
  color: #64ffda;
  padding: 10px 10px 10px 0px;
}

.nav > .nav-header > .nav-title:active {
  transition: all 0.1s ease-in-out;
  transform: scale(0.9);
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  display: inline;
  float: right;
  font-size: 15px;
}

.nav > .nav-links > p {
  display: inline-block;
  padding: 13px 0px 13px 10px;
  text-decoration: none;
  color: #64ffda;
}

.nav > .nav-links > a {
  display: inline-block;
  padding: 13px 20px 13px 10px;
  text-decoration: none;
  color: #b8c3e1;
}

.nav > .nav-links > a:hover {
  color: #64ffda;
  transition: all 0.2s ease-in-out;
}

.nav > .nav-links > a:active {
  transition: all 0.1s ease-in-out;
  transform: scale(0.9);
}

.nav > #nav-check {
  display: none;
}

.nav > .nav-links > .resume {
  color: #64ffda;
  border: 1.5px solid #64ffda;
  border-radius: 5px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 10px;
}

.nav > .nav-links > .resume:hover {
  background-color: #233554;
  transition: all 0.2s ease-in-out;
}

.nav > .nav-links > .resume:active {
  transition: all 0.1s ease-in-out;
  transform: scale(0.9);
}

.page__title {
  color: #e6f1ff;
  margin-top: 5rem;
  font-weight: 800;
  font-size: 5rem;
}

.socials {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: #64ffda;
}

.socials__item {
  font-size: 2.25rem;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.socials__item:hover {
  transform: scale(1.2);
}

.title {
  font-size: 300%;
  font-weight: 500;
  margin-bottom: 40px;
  background-image: linear-gradient(120deg, #233554 0%, #233554 100%);
  background-repeat: no-repeat;
  background-size: 55% 0.03em;
  background-position: 0 100%;
}

.title > p {
  display: inline-block;
  padding: 0px 20px 0px 0px;
  text-decoration: none;
  color: #64ffda;
}

.split__line {
  display: flex;
  align-items: flex-start;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  margin-left: 100px;
}

.about__text {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5;
  width: 55%;
}

.typewriter {
  font-size: 2rem;
  color: #a8b2d1;
}

.education {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  margin-left: 100px;
}

.projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  margin-left: 100px;
  margin-right: 100px;
}

.buttons {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.buttons__item {
  font-size: 1rem;
  margin-right: 2rem;
  margin-top: 1.5rem;
  cursor: pointer;
  border: 1px solid #64ffda;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: #64ffda;
  transition: all 0.2s ease-in-out;
}

.buttons__item:hover {
  transform: scale(1.1);
  background-color: #233554;
}

.buttons__item:active {
  transition: all 0.1s ease-in-out;
  transform: scale(1);
}

.project__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project__item {
  flex: 0 0 calc(50% - 20px);
  margin-bottom: 20px;
  border: 4px solid #233554;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  transition: 0.5s;
}

.project__item:hover {
  transform: scale(1.01);
}

.project__photo {
  width: 100%;
  height: 230px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border: 2px solid #64ffda;
  border-radius: 5px;
  filter: opacity(0.5) drop-shadow(0 0 0 #64ffda);
  transition: 0.5s;
}

.project__photo:hover {
  filter: none;
}

.project__split {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.project__title {
  font-size: 1.2em;
  color: #e6f1ff;
}

.project__links {
  font-size: 1.5rem;
  color: #e6f1ff;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
}

.project__link {
  transition: 0.2s;
}

.project__link:hover {
  transform: scale(1.2);
}

.project__tech {
  font-size: 0.8rem;
  font-weight: 200;
  margin-bottom: 10px;
  color: #64ffda;
}

.project__text {
  color: #a8b2d1;
  font-size: 1rem;
  font-weight: 300;
}

.publications {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  margin-left: 100px;
}

.paper__link {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5;
  color: #e6f1ff;
  position: relative;
  display: inline-block;
  transition: 0.2s;
}

.paper__link:hover {
  transform: scale(1.2);
}

.experience {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  margin-left: 100px;
}

.work-experience-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.menu-and-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  border-left: 2px solid #233554;
}

.menu li {
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px;
  color: #a8b2d1;
  transition: 0.2s;
}

.menu li:hover {
  color: #64ffda;
  border-left: 2px solid #64ffda;
}

.menu li:active {
  font-weight: 600;
  transform: scale(1.1);
}

.details {
  flex-grow: 1;
  margin-left: 20px;
  color: #a8b2d1;
}

.job__text {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  width: 100%;
}

.job__title {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5;
  color: #e6f1ff;
}

.small__text {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5;
  color: #a8b2d1;
}

.link__text {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5;
  color: #64ffda;
  position: relative;
  display: inline-block;
}

.link__text::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #64ffda;
  transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

@media (hover: hover) and (pointer: fine) {
  .link__text:hover::after {
    width: 100%;
  }
}

.photo {
  width: 25%;
  margin-left: 50px;
  border: 2px solid #64ffda;
  border-radius: 5px;
  filter: opacity(0.5) drop-shadow(0 0 0 #64ffda);
  transition: 0.5s;
}

.photo:hover {
  filter: none;
}

.reveal{
  position: relative;
  transform: translateY(50px);
  opacity: 0;
  transition: 0.5s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.down__animation {  
  animation: 2s ease-out 0s 1 slideInFromTop;
}

.right__animation {  
  animation: 2s ease-out 0s 1 slideInFromLeft;
}

.left__animation {
  animation: 2s ease-out 0s 1 slideInFromRight;
}

.appear__animation {
  animation: 2s ease-out 0s 1 appear;
}

@media (max-width:856px) {
  .nav {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }
  
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 12px;
  }

  .nav > .nav-header > .nav-title {
    display: inline-block;
    font-size: 35px;
    color: #64ffda;
  }
  
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
    cursor: pointer;
  }

  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #64ffda;
  }

  .nav > #nav-check:not(:checked) ~ .nav-btn > label > .turn-invis {
    transition: opacity 0.5s ease-in-out;
  }

  .nav > #nav-check:checked ~ .nav-btn > label > .turn-invis {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #0A192F;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 70px;
    left: 0px;
    text-align: center;
    font-size: 1.5rem;
  }

  .nav > .nav-links > a {
    display: block;
    width: 100%;
  }

  .nav > .nav-links > p {
    display: none;
  }

  .nav > .nav-links > .resume {
    color: #b8c3e1;
    border: 0;
    border-radius: 0;
    margin-left: -5px;
  }

  .nav > .nav-links > .resume:hover {
    background-color: #0A192F;
  }
  
  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }

  .nav > #nav-check:checked ~ .nav-links {
    height: calc(150vh);
  }

  .title {
    background-size: 80% 0.03em;
  }
}

@media (max-width:730px) {
  .page__title {
    font-size: 10vw;
  }

  .socials__item {
    font-size: 6vw;
  }

  .typewriter {
    font-size: 5vw;
  }

  .small__text {
    font-size: 4vw;
  }

  .link__text {
    font-size: 4vw;
  }

  .title {
    font-size: 8vw;
    background-size: 80% 0.03em;
  }

  .about {
    padding: 25px;
    margin-left: 0px;
  }

  .about__text {
    width: 100%;
  }

  .split__line {
    flex-direction: column;
  }
  
  .photo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
  }

  .experience {
    padding: 25px;
    margin-left: 0px;
  }

  .education {
    padding: 25px;
    margin-left: 0px;
  }

  .projects {
    padding: 25px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .buttons__item {
    font-size: 3vw;
    margin-right: 1rem;
  }

  .project__item {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }

  .publications {
    padding: 25px;
    margin-left: 0px;
  }
}